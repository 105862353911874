import React from 'react';
import Hero from '../Components/Hero/Hero';
import About from '../Components/About/About';
import Iconbox from '../Components/Iconbox/Iconbox';
import PriceSlider from '../Components/Slider/PriceSlider';
import Accordion from '../Components/Accordion/Accordion';

const heroData = {
  bgImg: 'images/hero-bg.jpg',
  bgShape: 'shape/hero-shape.png',
  sliderImages: [
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },
  ],
  title: ['Rücken-Impuls', 'Schlaf-Impuls', 'Waldbaden-Impuls'],
};

const aboutData = {
  title:
    'A hospital is a health care institution providing patient treatment with specialized medical',
  subTitle:
    'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid unt labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamc  laboris nisi ut aliquip. Commodo consequat.<br /><br />  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid unt labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamc laboris nisi ut aliquip. Commodo consequat. Sed do eiusmod  tempor incidid unt labore et dolore magna aliqua.',

  avater: {
    img: 'images/avatar1.png',
    name: 'Lisa García',
    designation: 'Gesundheitsexpertin, B.A. Fitnesstraining, M.A. Sport- und Bewegungsgerontologie',
  },
  timeTable: [
    {
      day: 'Montag bis Freitag',
    },
  ],
  contact: 'info@natuerl-ich.green',
};

const iconboxData = [
  {
    bg: 'purple',
    icon: 'icons/icon1.svg',
    title: 'Schlaf-Impuls',
    subTitle:
      'Lorem ipsum dolor sit amet consectet adipis sed do eiusmod tempor inc ididunt utid labore.',
  },
  {
    bg: 'green',
    icon: 'icons/icon2.svg',
    title: 'Rücken-Impuls',
    subTitle:
      'Lorem ipsum dolor sit amet consectet adipis sed do eiusmod tempor inc ididunt utid labore.',
  },
  {
    bg: 'red',
    icon: 'icons/icon3.svg',
    title: 'Waldbaden-Impuls',
    subTitle:
      'Lorem ipsum dolor sit amet consectet adipis sed do eiusmod tempor inc ididunt utid labore.',
  },
];

const priceData = [
  {
    title: 'Schlaf-Impuls',
    price: '120',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '0',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Hemoglobin Test',
    price: '89',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Homocysteine Test',
    price: '150',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '1',
      },
    ],
  },
  {
    title: 'Blood Test',
    price: '39',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '0',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Hemoglobin Test',
    price: '89',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Homocysteine Test',
    price: '150',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '1',
      },
    ],
  },
];

const faqData = {
  title: 'FAQS',
  img: 'images/faq-img.png',
  bgImg: 'shape/faq-bg.svg',
  faqItems: [
    {
      title: 'What is Medi solution?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: 'How do I get a refill on my prescription?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: 'How competent our total treatment?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: 'If I get sick what should I do?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: 'What is emergency cary to your hospital?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
  ],
};

const Home = () => {
  return (
    <>
      <Hero data={heroData} />
      <About data={aboutData} />
      <Iconbox data={iconboxData} />
      <PriceSlider data={priceData} />
      <Accordion data={faqData} />
    </>
  );
};

export default Home;
